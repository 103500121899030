<template>
  <moe-page title="新建标签" class="user-tag">
    <moe-form
      ref="userTagForm"
      :showBack="false"
      :showClose="true"
      :model="userTagParams"
      :rules="rules">
      <el-form-item label="标签名称" prop="name">
        <el-input v-model.trim="userTagParams.name" placeholder="请输入标签名称" clearable maxlength="100"></el-input>
      </el-form-item>

      <el-form-item label="描述">
        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model.trim="userTagParams.desc" placeholder="输入描述" maxlength="100" clearable/>
      </el-form-item>

      <div class="fwb mb-20 font-20">基本信息</div>

      <el-form-item prop="sexList">
        <div class="user-tag-form">
          <div class="user-tag-form__label">
            <moe-checkbox v-model="disabledOptions.sexListCheck" :trueLabel="1" :falseLabel="0">性别</moe-checkbox>
          </div>
          <el-checkbox-group :disabled="!disabledOptions.sexListCheck" v-model="userTagParams.sexList">
            <el-checkbox-button v-for="(item, index) in $moe_data.sexList" :key="index" :label="item.value">{{ item.label }}</el-checkbox-button>
          </el-checkbox-group>
        </div>
      </el-form-item>

      <el-form-item prop="age">
        <div class="user-tag-form">
          <div class="user-tag-form__label">
            <moe-checkbox v-model="disabledOptions.ageCheck" :trueLabel="1" :falseLabel="0">年龄</moe-checkbox>
          </div>
          <moe-input-number-range
            :disabled="!disabledOptions.ageCheck"
            :min="0"
            :checkStrictly="false"
            :value.sync="userTagParams.age.min"
            :value2.sync="userTagParams.age.max">
            <template slot="append">岁</template>
          </moe-input-number-range>
        </div>
      </el-form-item>

      <el-form-item prop="registerTime.type">
        <div class="df aic">
          <moe-checkbox v-model="disabledOptions.registerTimeCheck" :trueLabel="1" :falseLabel="0">注册时间</moe-checkbox>
        </div>
        <div>
          <div class="user-tag-form mb-10">
            <div class="user-tag-form__label">
              <moe-radio label="RANGE" :disabled="!disabledOptions.registerTimeCheck" v-model="userTagParams.registerTime.type" @change="$refs.userTagForm.validateField('registerTime.type')">注册时间</moe-radio>
            </div>
            <el-date-picker
              :disabled="userTagParams.registerTime.type !== 'RANGE'"
              placement="bottom-start"
              v-model="registerTime"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              @change="(registerTime) => ([userTagParams.registerTime.rangeMin, userTagParams.registerTime.rangeMax] = registerTime || ['', ''])" />
          </div>
          <div class="user-tag-form">
            <div class="user-tag-form__label">
              <moe-radio label="RELATIVE" :disabled="!disabledOptions.registerTimeCheck" v-model="userTagParams.registerTime.type" @change="$refs.userTagForm.validateField('registerTime.type')">距当前时间</moe-radio>
            </div>
            <moe-input-number-range
              :disabled="userTagParams.registerTime.type !== 'RELATIVE'"
              :min="0"
              :checkStrictly="false"
              :value.sync="userTagParams.registerTime.relativeMin"
              :value2.sync="userTagParams.registerTime.relativeMax">
              <template slot="append">天</template>
            </moe-input-number-range>
            <el-tooltip class="ml-10" content="距当前时间0-0 时为筛选当天" placement="top">
              <moe-icon name="reminder" size="20px"></moe-icon>
            </el-tooltip>
          </div>
        </div>
      </el-form-item>

      <el-form-item prop="deviceCount.min">
        <div class="user-tag-form">
          <div class="user-tag-form__label">
            <moe-checkbox v-model="disabledOptions.deviceCountCheck" :trueLabel="1" :falseLabel="0">关联设备数</moe-checkbox>
          </div>
          <moe-input-number-range
            :disabled="!disabledOptions.deviceCountCheck"
            :min="0"
            :checkStrictly="false"
            :value.sync="userTagParams.deviceCount.min"
            :value2.sync="userTagParams.deviceCount.max">
          </moe-input-number-range>
          <el-tooltip content="关联设备数量0-0 时为未绑定设备用户" placement="top">
            <moe-icon name="reminder" size="20px"></moe-icon>
          </el-tooltip>
        </div>
      </el-form-item>

      <el-form-item prop="deviceModeIdList">
        <div class="user-tag-form">
          <div class="user-tag-form__label">
            <moe-checkbox v-model="disabledOptions.deviceModeIdListCheck" :trueLabel="1" :falseLabel="0">关联设备型号</moe-checkbox>
          </div>
          <el-select class="w-400" :disabled="!disabledOptions.deviceModeIdListCheck" v-model="userTagParams.deviceModeIdList" multiple filterable clearable placeholder="请选择设备型号">
            <el-option v-for="(item, index) in deviceModelList" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
      </el-form-item>

      <el-form-item prop="petCount.min">
        <div class="user-tag-form">
          <div class="user-tag-form__label">
            <moe-checkbox v-model="disabledOptions.petCountCheck" :trueLabel="1" :falseLabel="0">宠物数量</moe-checkbox>
          </div>
          <moe-input-number-range
            :disabled="!disabledOptions.petCountCheck"
            :min="0"
            :checkStrictly="false"
            :value.sync="userTagParams.petCount.min"
            :value2.sync="userTagParams.petCount.max">
            <template slot="append">只</template>
          </moe-input-number-range>
          <el-tooltip class="ml-10" content="宠物数量0-0 时为未绑定宠物用户" placement="top">
            <moe-icon name="reminder" size="20px"></moe-icon>
          </el-tooltip>
        </div>
      </el-form-item>

      <el-form-item prop="petSpecieList">
        <div class="user-tag-form">
          <div class="user-tag-form__label">
            <moe-checkbox v-model="disabledOptions.petSpecieListCheck" :trueLabel="1" :falseLabel="0">宠物类型</moe-checkbox>
          </div>
          <el-checkbox-group :disabled="!disabledOptions.petSpecieListCheck" v-model="userTagParams.petSpecieList">
            <el-checkbox-button v-for="(item, index) in $moe_data.petSpecieList" :key="index" :label="item.value">{{ item.label }}</el-checkbox-button>
          </el-checkbox-group>
        </div>
      </el-form-item>

      <el-form-item prop="petSexList">
        <div class="user-tag-form">
          <div class="user-tag-form__label">
            <moe-checkbox v-model="disabledOptions.petSexListCheck" :trueLabel="1" :falseLabel="0">宠物性别</moe-checkbox>
          </div>
          <el-checkbox-group :disabled="!disabledOptions.petSexListCheck" v-model="userTagParams.petSexList">
            <el-checkbox-button v-for="(item, index) in $moe_data.petSexList" :key="index" :label="item.value">{{ item.label }}</el-checkbox-button>
          </el-checkbox-group>
        </div>
      </el-form-item>

      <el-form-item prop="petTypeIdList">
        <div class="user-tag-form">
          <div class="user-tag-form__label">
            <moe-checkbox v-model="disabledOptions.petTypeIdListCheck" :trueLabel="1" :falseLabel="0">宠物品种</moe-checkbox>
          </div>
          <el-select class="w-400" :disabled="!disabledOptions.petTypeIdListCheck" v-model="userTagParams.petTypeIdList" multiple filterable clearable placeholder="请选择宠物品种">
            <el-option
              v-for="item in petTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </el-form-item>

      <div class="fwb mb-20 font-20">交易条件</div>

      <el-form-item prop="lastConsumeTime.type">
        <div class="df aic">
          <moe-checkbox v-model="disabledOptions.lastConsumeTimeCheck" :trueLabel="1" :falseLabel="0">最近消费时间</moe-checkbox>
        </div>
        <div class="">
          <div class="user-tag-form mb-10">
            <div class="user-tag-form__label">
              <moe-radio label="RANGE" :disabled="!disabledOptions.lastConsumeTimeCheck" v-model="userTagParams.lastConsumeTime.type" @change="$refs.userTagForm.validateField('lastConsumeTime.type')">最近消费时间</moe-radio>
            </div>
            <el-date-picker
              :disabled="userTagParams.lastConsumeTime.type !== 'RANGE'"
              placement="bottom-start"
              v-model="lastConsumeTime"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              @change="(lastConsumeTime) => ([userTagParams.lastConsumeTime.rangeMin, userTagParams.lastConsumeTime.rangeMax] = lastConsumeTime || ['', ''])" />
          </div>
          <div class="user-tag-form">
            <div class="user-tag-form__label">
              <moe-radio label="RELATIVE" :disabled="!disabledOptions.lastConsumeTimeCheck" v-model="userTagParams.lastConsumeTime.type" @change="$refs.userTagForm.validateField('lastConsumeTime.type')">距当前时间</moe-radio>
            </div>
            <moe-input-number-range
              :disabled="userTagParams.lastConsumeTime.type !== 'RELATIVE'"
              :min="0"
              :checkStrictly="false"
              :value.sync="userTagParams.lastConsumeTime.relativeMin"
              :value2.sync="userTagParams.lastConsumeTime.relativeMax">
              <template slot="append">天</template>
            </moe-input-number-range>
            <el-tooltip class="ml-10" content="距当前时间0-0 时为筛选当天" placement="top">
              <moe-icon name="reminder" size="20px"></moe-icon>
            </el-tooltip>
          </div>
        </div>
      </el-form-item>

      <el-form-item prop="lastConsumeCount.dayRange.min">
        <div class="user-tag-form">
          <div class="user-tag-form__label">
            <el-checkbox v-model="disabledOptions.lastConsumeCountCheck" :trueLabel="1" :falseLabel="0">消费次数</el-checkbox>
          </div>
          <div class="mr-10">距当前时间</div>
          <moe-input-number-range
            :disabled="!disabledOptions.lastConsumeCountCheck"
            :min="0"
            :checkStrictly="false"
            :value.sync="userTagParams.lastConsumeCount.dayRange.min"
            :value2.sync="userTagParams.lastConsumeCount.dayRange.max">
          </moe-input-number-range>
          <div class="mr-10">天内消费</div>
          <moe-input-number-range
            :disabled="!disabledOptions.lastConsumeCountCheck"
            :min="0"
            :checkStrictly="false"
            :value.sync="userTagParams.lastConsumeCount.countRange.min"
            :value2.sync="userTagParams.lastConsumeCount.countRange.max">
            <template slot="append">次</template>
          </moe-input-number-range>
          <el-tooltip class="ml-10" content="距当前时间0-0 时为筛选当天" placement="top">
            <moe-icon name="reminder" size="20px"></moe-icon>
          </el-tooltip>
        </div>
      </el-form-item>

      <el-form-item prop="lastConsumePrice.dayRange.min">
        <div class="user-tag-form">
          <div class="user-tag-form__label">
            <el-checkbox v-model="disabledOptions.lastConsumePriceCheck" :trueLabel="1" :falseLabel="0">消费金额</el-checkbox>
          </div>
          <div class="mr-10">距当前时间</div>
          <moe-input-number-range
            :disabled="!disabledOptions.lastConsumePriceCheck"
            :min="0"
            :checkStrictly="false"
            :value.sync="userTagParams.lastConsumePrice.dayRange.min"
            :value2.sync="userTagParams.lastConsumePrice.dayRange.max">
          </moe-input-number-range>
          <div class="mr-10">天内消费</div>
          <moe-input-number-range
            :disabled="!disabledOptions.lastConsumePriceCheck"
            :min="0"
            :checkStrictly="false"
            :value.sync="userTagParams.lastConsumePrice.moneyRange.min"
            :value2.sync="userTagParams.lastConsumePrice.moneyRange.max">
            <template slot="append">元</template>
          </moe-input-number-range>
          <el-tooltip class="ml-10" content="距当前时间0-0 时为筛选当天" placement="top">
            <moe-icon name="reminder" size="20px"></moe-icon>
          </el-tooltip>
        </div>
      </el-form-item>

      <el-form-item prop="lastConsumeAvgPrice.dayRange.min">
        <div class="user-tag-form">
          <div class="user-tag-form__label">
            <el-checkbox v-model="disabledOptions.lastConsumeAvgPriceCheck" :trueLabel="1" :falseLabel="0">订单均价</el-checkbox>
          </div>
          <div class="mr-10">距当前时间</div>
          <moe-input-number-range
            :disabled="!disabledOptions.lastConsumeAvgPriceCheck"
            :min="0"
            :checkStrictly="false"
            :value.sync="userTagParams.lastConsumeAvgPrice.dayRange.min"
            :value2.sync="userTagParams.lastConsumeAvgPrice.dayRange.max">
          </moe-input-number-range>
          <div class="mr-10">天内均价</div>
          <moe-input-number-range
            :disabled="!disabledOptions.lastConsumeAvgPriceCheck"
            :min="0"
            :checkStrictly="false"
            :value.sync="userTagParams.lastConsumeAvgPrice.moneyRange.min"
            :value2.sync="userTagParams.lastConsumeAvgPrice.moneyRange.max">
            <template slot="append">元</template>
          </moe-input-number-range>
          <el-tooltip class="ml-10" content="距当前时间0-0 时为筛选当天" placement="top">
            <moe-icon name="reminder" size="20px"></moe-icon>
          </el-tooltip>
        </div>
      </el-form-item>

      <el-button slot="tool" type="primary" icon="el-icon-thumb" :loading="userTagLoad" @click="handleSubmit()">{{ userTagLoad ? '保存中' : '提交' }}</el-button>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'UserManageTagsManageAdd',
  computed: {
    IS_EDIT() { return this.userTagParams.id ? true : false },
  },
  data() {
    const verify = this.$moe_verify.verifyForm;
    // 性别
    const checkSexList = (rule, value, callback) => {
      const { sexListCheck } = this.disabledOptions;
      if (sexListCheck && !value.length) {
        return callback(new Error(`请选择性别`));
      }
      callback();
    }
    // 年龄
    const checkAge = (rule, value, callback) => {
      const { ageCheck } = this.disabledOptions;
      if (ageCheck && !value) {
        return callback(new Error(`请选择年龄`));
      }
      callback();
    }
    // 注册时间
    const checkRegisterTime = (rule, value, callback) => {
      const { registerTimeCheck } = this.disabledOptions;
      const { registerTime: { type, relativeMin, rangeMin } } = this.userTagParams;
      if (registerTimeCheck && !value) {
        return callback(new Error(`请选择注册时间类型`));
      }
      if (type === 'RANGE' && !rangeMin) {
        return callback(new Error(`请选择注册时间范围`));
      }
      callback();
    }
    // 关联设备数
    const checkDeviceCount = (rule, value, callback) => {
      // const { deviceCountCheck } = this.disabledOptions;
      // if (deviceCountCheck && !value) {
      //   return callback(new Error(`请输入关联设备数量`));
      // }
      callback();
    }
    // 关联设备型号
    const checkDeviceModeIdList = (rule, value, callback) => {
      const { deviceModeIdListCheck } = this.disabledOptions;
      if (deviceModeIdListCheck && !value.length) {
        return callback(new Error(`请选择关联设备型号`));
      }
      callback();
    }
    // 宠物数量
    const checkPetCount = (rule, value, callback) => {
      callback();
    }
    // 宠物类型
    const checkPetSpecieList = (rule, value, callback) => {
      const { petSpecieListCheck } = this.disabledOptions;
      if (petSpecieListCheck && !value.length) {
        return callback(new Error(`请选择宠物类型`));
      }
      callback();
    }
    // 宠物性别
    const checkPetSexList = (rule, value, callback) => {
      const { petSexListCheck } = this.disabledOptions;
      if (petSexListCheck && !value.length) {
        return callback(new Error(`请选择宠物性别`));
      }
      callback();
    }
    // 宠物品种
    const checkPetTypeIdList = (rule, value, callback) => {
      const { petTypeIdListCheck } = this.disabledOptions;
      if (petTypeIdListCheck && !value.length) {
        return callback(new Error(`请选择宠物品种`));
      }
      callback();
    }
    // 最近消费时间
    const checkLastConsumeTime = (rule, value, callback) => {
      const { lastConsumeTimeCheck } = this.disabledOptions;
      const { lastConsumeTime: { type, relativeMin, rangeMin } } = this.userTagParams;
      if (lastConsumeTimeCheck && !value) {
        return callback(new Error(`请选择最近消费时间类型`));
      }
      if (type === 'RANGE' && !rangeMin) {
        return callback(new Error(`请选择最近消费时间范围`));
      }
      callback();
    }
    // 消费次数
    const checkLastConsumeCount = (rule, value, callback) => {
      const { lastConsumeCountCheck } = this.disabledOptions;
      const { lastConsumeCount: { dayRange, countRange } } = this.userTagParams;
      if (lastConsumeCountCheck) {
        if (!countRange.min) {
          return callback(new Error(`请输入消费次数`));
        }
      }
      callback();
    }
    // 消费金额
    const checkLastConsumePrice = (rule, value, callback) => {
      const { lastConsumePriceCheck } = this.disabledOptions;
      const { lastConsumePrice: { dayRange, moneyRange } } = this.userTagParams;
      if (lastConsumePriceCheck) {
        if (!moneyRange.min) {
          return callback(new Error(`请输入消费金额`));
        }
      }
      callback();
    }
    // 订单均价
    const checkLastConsumeAvgPrice = (rule, value, callback) => {
      const { lastConsumeAvgPriceCheck } = this.disabledOptions;
      const { lastConsumeAvgPrice: { dayRange, moneyRange } } = this.userTagParams;
      if (lastConsumeAvgPriceCheck) {
        if (!moneyRange.min) {
          return callback(new Error(`请输入均价`));
        }
      }
      callback();
    }
    return {
      userTagParams: {
        id: '',
        name: '',
        desc: '',
        sexList: [],
        age: {
          min: 0,
          max: 0,
        },
        registerTime: {
          type: '',
          relativeMin: 0,
          relativeMax: 0,
          rangeMin: '',
          rangeMax: '',
        },
        deviceCount: {
          min: 0,
          max: 0,
        },
        deviceModeIdList: [],
        petCount: {
          min: 0,
          max: 0,
        },
        petSpecieList: [],
        petSexList: [],
        petTypeIdList: [],
        lastConsumeTime: {
          type: '',
          relativeMin: 0,
          relativeMax: 0,
          rangeMin: '',
          rangeMax: '',
        },
        lastConsumeCount: {
          dayRange: {
            min: 0,
            max: 0,
          },
          countRange: {
            min: 0,
            max: 0,
          }
        },
        lastConsumePrice: {
          dayRange: {
            min: 0,
            max: 0,
          },
          moneyRange: {
            min: 0,
            max: 0,
          },
        },
        lastConsumeAvgPrice: {
          dayRange: {
            min: 0,
            max: 0,
          },
          moneyRange: {
            min: 0,
            max: 0,
          },
        },
      },
      registerTime: [],
      deviceModelList: [],
      petTypeList: [],
      lastConsumeTime: [],
      rules: {
        name: verify.isExtent(['请输入标签名称', '长度为 1 ~ 26位'], '1~26'),
        sexList: [{ required: true, validator: checkSexList, trigger: ['blur', 'change'] }],
        age: [{ required: true, validator: checkAge, trigger: ['blur', 'change'] }],
        'registerTime.type': [{ required: true, validator: checkRegisterTime, trigger: ['blur', 'change'] }],
        'deviceCount.min': [{ required: true, validator: checkDeviceCount, trigger: ['blur', 'change'] }],
        'petCount.min': [{ required: true, validator: checkPetCount, trigger: ['blur', 'change'] }],
        deviceModeIdList: [{ required: true, validator: checkDeviceModeIdList, trigger: ['blur', 'change'] }],
        petSpecieList: [{ required: true, validator: checkPetSpecieList, trigger: ['blur', 'change'] }],
        petSexList: [{ required: true, validator: checkPetSexList, trigger: ['blur', 'change'] }],
        petTypeIdList: [{ required: true, validator: checkPetTypeIdList, trigger: ['blur', 'change'] }],
        'lastConsumeTime.type': [{ required: true, validator: checkLastConsumeTime, trigger: ['blur', 'change'] }],
        'lastConsumeCount.dayRange.min': [{ required: true, validator: checkLastConsumeCount, trigger: ['blur', 'change'] }],
        'lastConsumePrice.dayRange.min': [{ required: true, validator: checkLastConsumePrice, trigger: ['blur', 'change'] }],
        'lastConsumeAvgPrice.dayRange.min': [{ required: true, validator: checkLastConsumeAvgPrice, trigger: ['blur', 'change'] }],
      },
      disabledOptions: {
        sexListCheck: 0,               // 性别
        ageCheck: 0,                   // 年龄
        registerTimeCheck: 0,          // 注册时间
        deviceCountCheck: 0,           // 关联设备数
        deviceModeIdListCheck: 0,      // 关联设备型号
        petCountCheck: 0,              // 宠物数量
        petSpecieListCheck: 0,         // 宠物类型
        petSexListCheck: 0,            // 宠物性别
        petTypeIdListCheck: 0,         // 宠物品种
        lastConsumeTimeCheck: 0,       // 最近消费时间
        lastConsumeCountCheck: 0,      // 消费次数
        lastConsumePriceCheck: 0,      // 消费金额
        lastConsumeAvgPriceCheck: 0,   // 订单均价
      },
      userTagLoad: false,
    }
  },
  methods: {
    handleSubmit() {
      this.$refs['userTagForm'].validate(() => {
        this.userTagLoad = true;
        const { sexListCheck, ageCheck, registerTimeCheck, deviceCountCheck, deviceModeIdListCheck, petCountCheck, petSpecieListCheck,
          petSexListCheck, petTypeIdListCheck, lastConsumeTimeCheck, lastConsumeCountCheck, lastConsumePriceCheck, lastConsumeAvgPriceCheck } = this.disabledOptions;
        const { id, name, desc, sexList, age, registerTime, deviceCount, deviceModeIdList, petCount, petSpecieList, petSexList, petTypeIdList,
        lastConsumeTime, lastConsumeCount, lastConsumePrice, lastConsumeAvgPrice } = this.userTagParams;
        let params = {
          id, name, desc,
          filter: {
            sexList: sexListCheck ? sexList : null,
            age: ageCheck ? age : null,
            registerTime: registerTimeCheck ? {
              type: registerTime.type,
              relativeMin: registerTime.type === 'RELATIVE' ? (registerTime.relativeMin || 0) : null,
              relativeMax: registerTime.type === 'RELATIVE' ? (registerTime.relativeMax || 0) : null,
              rangeMin: registerTime.type === 'RANGE' ? registerTime.rangeMin : null,
              rangeMax: registerTime.type === 'RANGE' ? registerTime.rangeMax : null,
            } : null,
            deviceCount: deviceCountCheck ? deviceCount : null,
            deviceModeIdList: deviceModeIdListCheck ? deviceModeIdList : null,
            petCount: petCountCheck ? petCount : null,
            petSpecieList: petSpecieListCheck ? petSpecieList : null,
            petSexList: petSexListCheck ? petSexList : null,
            petTypeIdList: petTypeIdListCheck ? petTypeIdList : null,
            lastConsumeTime: lastConsumeTimeCheck ? {
              type: lastConsumeTime.type,
              relativeMin: lastConsumeTime.type === 'RELATIVE' ? (lastConsumeTime.relativeMin || 0) : null,
              relativeMax: lastConsumeTime.type === 'RELATIVE' ? (lastConsumeTime.relativeMax || 0) : null,
              rangeMin: lastConsumeTime.type === 'RANGE' ? lastConsumeTime.rangeMin : null,
              rangeMax: lastConsumeTime.type === 'RANGE' ? lastConsumeTime.rangeMax : null,
            } : null,
            lastConsumeCount: lastConsumeCountCheck ? lastConsumeCount : null,
            lastConsumePrice: lastConsumePriceCheck ? lastConsumePrice : null,
            lastConsumeAvgPrice: lastConsumeAvgPriceCheck ? lastConsumeAvgPrice : null,
          },
        }

        if (this.IS_EDIT) {
          this.req_updateUserTag(params);
        } else {
          this.req_addUserTag(params);
        }
      })
    },
    /** 创建用户标签 */
    async req_addUserTag(params) {
      const { code, message } = await this.$moe_api.USER_API.addUserTag(params);
      this.userTagLoad = false;
      if (code === 200) {
        this.$moe_msg.success('新建标签成功', {
          completionHandler: () => {
            this.$moe_coordinator.navigateBack();
          }
        });
      } else {
        this.$moe_msg.error(message);
      }
    },
    /** 修改用户标签 */
    async req_updateUserTag(params) {
      const { code, message } = await this.$moe_api.USER_API.updateUserTag(params);
      this.userTagLoad = false;
      if (code === 200) {
        this.$moe_msg.success('编辑标签成功', {
          completionHandler: () => {
            this.$moe_coordinator.navigateBack();
          }
        });
      } else {
        this.$moe_msg.error(message);
      }
    },
    /** 获取用户标签详情 */
    req_getUserTagDetail() {
      if (this.$route.query.id) {
        this.$moe_api.USER_API.getUserTagDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code === 200) {
            const { id, name, desc, filter:
              { sexList, age, registerTime, deviceCount, deviceModeIdList, petCount, petSpecieList, petSexList, petTypeIdList,
                lastConsumeTime, lastConsumeCount, lastConsumePrice, lastConsumeAvgPrice }
            } = data.result;
            this.disabledOptions = {
              sexListCheck: sexList?.length ? 1 : 0,
              ageCheck: age ? 1 : 0,
              registerTimeCheck: registerTime ? 1 : 0,
              deviceCountCheck: deviceCount ? 1 : 0,
              deviceModeIdListCheck: deviceModeIdList?.length ? 1 : 0,
              petCountCheck: petCount ? 1 : 0,
              petSpecieListCheck: petSpecieList?.length ? 1 : 0,
              petSexListCheck: petSexList?.length ? 1 : 0,
              petTypeIdListCheck: petTypeIdList?.length ? 1 : 0,
              lastConsumeTimeCheck: lastConsumeTime ? 1 : 0,
              lastConsumeCountCheck: lastConsumeCount ? 1 : 0,
              lastConsumePriceCheck: lastConsumePrice ? 1 : 0,
              lastConsumeAvgPriceCheck: lastConsumeAvgPrice ? 1 : 0,
            };
            this.userTagParams = {
              id,
              name,
              desc,
              sexList: sexList ? sexList : [],
              age: age ? age : {
                min: 0,
                max: 0,
              },
              registerTime: registerTime ? registerTime : {
                type: '',
                relativeMin: '',
                relativeMax: '',
                rangeMin: 0,
                rangeMax: 0,
              },
              deviceCount: deviceCount ? deviceCount : {
                min: 0,
                max: 0,
              },
              deviceModeIdList: deviceModeIdList ? deviceModeIdList : [],
              petCount: petCount ? petCount : {
                min: 0,
                max: 0,
              },
              petSpecieList: petSpecieList ? petSpecieList : [],
              petSexList: petSexList ? petSexList : [],
              petTypeIdList: petTypeIdList ? petTypeIdList : [],
              lastConsumeTime: lastConsumeTime ? lastConsumeTime : {
                type: '',
                relativeMin: '',
                relativeMax: '',
                rangeMin: 0,
                rangeMax: 0,
              },
              lastConsumeCount: lastConsumeCount ? lastConsumeCount : {
                dayRange: {
                  min: 0,
                  max: 0,
                },
                countRange: {
                  min: 0,
                  max: 0,
                }
              },
              lastConsumePrice: lastConsumePrice ? lastConsumePrice : {
                dayRange: {
                  min: 0,
                  max: 0,
                },
                moneyRange: {
                  min: 0,
                  max: 0,
                },
              },
              lastConsumeAvgPrice: lastConsumeAvgPrice ? lastConsumeAvgPrice : {
                dayRange: {
                  min: 0,
                  max: 0,
                },
                moneyRange: {
                  min: 0,
                  max: 0,
                },
              }
            };
            if (registerTime && registerTime.type === 'RANGE') {
              this.registerTime = [registerTime.rangeMin, registerTime.rangeMax];
            }
            if (lastConsumeTime && lastConsumeTime.type === 'RANGE') {
              this.lastConsumeTime = [lastConsumeTime.rangeMin, lastConsumeTime.rangeMax];
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    },
    /** 获取所有设备型号 */
    req_deviceTypeReadAll() {
      this.$moe_api.EQUIPMENT_API.readAll().then((data) => {
        if (data.code === 200) {
          this.deviceModelList = data.result.map(({ name, id }) => {
            return {
              label: name,
              value: id
            }
          })
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 获取所有宠物类别列表 */
    req_getPetsTypeAllList() {
      this.$moe_api.PET_API.getPetsTypeAllList().then((data) => {
        if (data.code === 200) {
          this.petTypeList = data.result.map(({ varieties, id }) => {
            return {
              label: varieties,
              value: id
            }
          })
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    }
  },
  mounted() {
    this.req_deviceTypeReadAll();
    this.req_getPetsTypeAllList();
    this.req_getUserTagDetail();
  },
}
</script>

<style lang="scss">
.user-tag {
  &-form {
    display: flex;
    align-items: center;
    white-space: nowrap;
    &__label {
      min-width: 130px;
      width: 130px;
    }
  }
}
</style>